
.terms-header {
    font-size: 18px;
    border: none;
    background-color: transparent;
  }
  
  @media (max-width: 768px) {
    .terms-header {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    .terms-header {
      font-size: 14px;
    }
  }
  