@use "../../config/styleVariable.scss" as global;
.Careers-container {
    // position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../assets/images/careerbg.jpg") no-repeat center top/cover;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    min-height: 600px;
  }
  
  .Careers-text-container {
    position: relative;
    z-index: 2;
  }

  .Careers-navBar{
    padding: 40px;
    justify-content: end;
  }

  .social-icon {
    font-size: 36px; 
    margin-right: 30px;
    color: white;
    transition: color 0.3s ease; 
  }
  .social-icon:hover {
    color: global.$primary;
  }
  


