@use "../../config/styleVariable.scss" as global;
.Admin-box {
  padding-top: 5px;
  padding-right: 10px;
  height: 100%;
  min-height: 84vh;
  max-height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
}
.Screen-sideBox {
  width: 100%;
  height: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgb(249, 249, 249);
}
.admimSidebarBox {
  margin-top: -5px;
  width: 100%;
  height: 100%;
  min-height: 85vh;
  max-height: 100vh;
  border-right: 1px solid #dbdbdb;
  overflow-x: hidden;
  padding: 14px;
}
/* width */
.admimSidebarBox::-webkit-scrollbar {
  width: 10px;
}
.admimSidebarBox::-webkit-scrollbar-track {
  background: #ffffff;
}
/* Handle */
.admimSidebarBox::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}
.sideItem {
  display: flex;
  flex-direction: row;
  color: rgb(76, 76, 76);
  padding: 6px;
  padding-left: 12px;
  border-radius: 6px;
  margin-bottom: 1px;
  &:hover {
    background-color: rgb(229, 229, 229);
    color: global.$primary;
    cursor: pointer;
    width: 100%;
  }
}
.sideItem-selected {
  display: flex;
  align-items: center;
  background-color: rgb(229, 229, 229);
  color: global.$primary;
  width: 100%;
  padding: 6px;
  padding-left: 12px;
  border-radius: 6px;
  margin-bottom: 1px;
}
.sideItemTxt1 {
  font-family: global.$SemiBold;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
  padding-top: 2px;
  color: #000;
}
.admimSidebar-storeBox {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgb(224, 224, 224);
  padding: 10px;
  border-radius: 6px;
  background-color: #fff;
}
.admimSidebar-logo {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid global.$primary;
  padding: 1px;
  background-color: #dbdbdb;
}
.admimSidebar-txt1 {
  font-family: global.$SemiBold;
  font-size: 14px;
}
.admimSidebar-txt2 {
  font-family: global.$Medium;
  font-size: 12px;
  color: green;
}
