@use "../../config/styleVariable.scss" as global;
.sellerimg {
  width: 100px;
}
.sellerRegister-box2 {
  height: 100%;
  min-height: 50vh;
  border-left: 1px solid global.$grey5;
  padding-left: 20px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-left: 0px;
    border-left: 0px;
  }
}

.sellerRegister-banner-item {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 39px;
  text-align: center;
  @media screen {
    padding: 0%;
  }
}

.sellerRegister-step3row2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.IndividualStep1-Box1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sellerRegister-Txt2 {
  font-family: global.$SemiBold;
  font-size: 15px;
}
.sellerRegister-box4 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.sellerRegister-box5 {
  border: 1px solid lightgrey;
  border-radius: 7px;
  padding: 40px;
  @media screen and (max-width: 768px) {
    border: none;
  }
}
.individualSellerPage-Box5 {
  max-width: 100%;
  height: 300px;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    height: 200px;
  }
}
.sellerRegister-box12 {
  background-color: rgb(240, 240, 240);
  padding: 20px;
  border-radius: 10px;
}
.sellerRegister-text13 {
  font-size: 15px;
}
.text-secondary {
  font-size: 15px;
}

.sellerRegister-Txt8 {
  font-family: global.$Bold;
  font-size: 30px;
}

// new styles
.sellerRegister-Heading {
  font-size: 30px;
  font-family: global.$SemiBold;
  text-align: left;
  color: #000;
}
.sellerRegister-subHeading {
  font-size: 25px;
  font-family: global.$SemiBold;
  text-align: left;
  color: #000;
}
.sellerRegister-text1 {
  font-size: 14px;
  font-family: global.$Regular;
  color: global.$grey1;
  letter-spacing: 0.05em;
}
.sellerRegister-text2 {
  font-size: 15px;
  font-family: global.$SemiBold;
}
.sellerRegister-ImagePicker {
  border: 2px dashed global.$grey5;
  padding: 15px;
  border-radius: 6px;
}
.sellerRegister-ImagePickerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: global.$SemiBold;
  font-size: 15px;
}
.sellerRegister-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sellerRegister-fileCard {
  border: 1px solid global.$grey5;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px;
  font-family: global.$SemiBold;
}
