@use "../../config/styleVariable.scss" as global;
.AddProducts-sideBox {
  height: 100%;
}
.AddProducts-stepBox {
  font-family: global.$SemiBold;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
  border-radius: 10px;
  cursor: pointer;
  &.active {
    background-color: rgb(240, 240, 240);
    color: global.$primary;
  }
  &:hover{
    background-color: rgb(240, 240, 240);
  }
}
.AddProducts-stepBoxIcon {
  margin-right: 10px;
  height: 27px;
  width: 27px;
  border: 1px solid global.$grey5;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.AddProducts-sideBox2 {
  border-left: 1px solid #dbdbdb;
  border-top: 0px;
  padding: 10px;
  min-height: 77.9vh;
  padding-right: 0px;
}
.AddProducts-sideBox2::-webkit-scrollbar {
  width: 10px;
}
.AddProducts-sideBox2::-webkit-scrollbar-track {
  background: #ffffff;
}
/* Handle */
.AddProducts-sideBox2::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}
.AddProducts-ImagePicker {
  border-radius: 6px;
  border: 2px dashed global.$grey5;
  background-color: global.$backround;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: global.$Medium;
  font-size: 13px;
  text-align: center;
  color: grey;
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
}
.AddProducts-newImagBox {
  border-radius: 6px;
  border: 2px dashed global.$grey5;
  background-color: global.$backround;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-family: global.$Bold;
  font-size: 13px;
  text-align: center;
  color: rgb(38, 38, 160);
}
.AddVariantType-box {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.AddVariantType-box2 {
  border: 1px dotted global.$grey7;
  padding: 10px;
  border-radius: 6px;
}
.VariantFromTable-txt1 {
  height: 50px;
  display: flex;
  align-items: center;
}
.AddProducts-VariantsImage {
  height: 50px;
  width: 50px;
  border: 2px dashed global.$grey5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
}
.AddProducts-ImagePickerBox {
  height: 400px;
  width: 500px;
  border: 2px dashed global.$grey5;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: gray;
}
.AddProducts-imagePickerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.AddProducts-ImagePickerClose {
  position: absolute;
  top: -12px;
  right: -10px;
}
.AddProducts-VariantsImageImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-table-list-image{
  height: 30px;
  width: 40px;
  object-fit: contain;
}
