//colors
$primary: #575f99;
$backround: #fafafa;
$grey1: #262941;
$grey2: #888c99;
$grey3: #eff1f5;
$grey4: #9a9eaa;
$grey5: #d9d9d9;
$grey6: #d1d1d1;
$grey7: #888888;
$grey8: #e2e2e9;
$grey9: #949191;

//font
$Regular: "DMSans-Regular";
$Medium: "DMSans-Medium";
$SemiBold: "DMSans-SemiBold";
$Bold: "DMSans-Bold";
$Light: "DMSans-Thin";
