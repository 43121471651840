@use "../../config/styleVariable.scss" as global;
$sidebar_menu_color: #f0e9ed;
$sidebar_menu_hover: #f0efef;

.empty-complaints{
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 5%;
  }
  
  .empty-complaint-text{
    font-size: 22px;
    font-weight: 600;
    color: global.$primary;
  }
  
  .complaint-report-button{
    color: global.$primary
  }