@use "../../config/styleVariable.scss" as global;

.driverRegister-box2 {
    height: 90%;
    min-height: 50vh;
    border-left: 1px solid global.$grey5;
    padding-left: 20px;
    @media screen and (min-width: 320px) and (max-width: 768px) {
      padding-left: 0px;
      border-left: 0px;
    }
  }

  .driverRegister-subHeading {
    font-size: 25px;
    font-family: global.$SemiBold;
    text-align: left;
    color: #000;
  }

  .driverRegister-errortxt {
    font-family: global.$Medium;
    color: red;
    font-size: 16px;
    margin-bottom: 20px;
    margin-bottom: 10px;
  }

  .driverRegister-text1 {
    font-size: 14px;
    font-family: global.$Regular;
    color: global.$grey1;
    letter-spacing: 0.05em;
  }
  .driverRegister-text2 {
    font-size: 15px;
    font-family: global.$SemiBold;
  }