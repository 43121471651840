.LatestCatalogue-col1{
  position: relative;
  padding-top: max(120%, 320px);
  height: 0;
  width: 100%;
}
.LatestCatalogue-frame{
  position: absolute;
  border: none;
  width: 100%;
  height: 100%;
  inset: 0;
}
